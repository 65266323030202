import React from "react";
import moment from "moment";
import BookitApiService from "../../../../../services/bookit";

const SuiteCareContext = React.createContext();

const initialState = {
  activeTab: null, // normal or billable
  refresh: false,
  session: {},
  pageLoading: false,
  defaultdaterange: [...Array(90)].map((_, index) =>
    moment().add(index, "days")
  ),
  ordersMastersByProgram: [],
  aggregatedMastersData: [],
  aggregatedMastersDataFiltered: [], // can be filtered by city or input field

  cities: [],
  selectedCity: "All",
  searchedText: "",

  selectedOrder: {},
  selectedMasterForOrderDetails: {},

  selectedSchedule: {},

  selectedMasterForReservation: {},
};

const SuiteCareProvider = ({ children }) => {
  const [suiteCareState, setSuiteCareState] = React.useState(initialState);

  const updateState = (newState) => {
    setSuiteCareState((prevState) => ({ ...prevState, ...newState }));
  };

  const refreshPage = () => {
    updateState({ refresh: !suiteCareState.refresh });
  };

  const changePageLoadingStatus = (status) => {
    updateState({ pageLoading: status });
  };

  const changeActiveTab = (tab) => {
    updateState({ activeTab: tab });
  };

  const checkAndStoreSessionDetails = async () => {
    const sessionResponse = await BookitApiService.checkSession();
    updateState({ session: sessionResponse });

    if (sessionResponse.loggedIn == false) {
      window.location.href = "/SuiteCare";
    }

    const { ClientProfileCardDetails } = sessionResponse;
    const Availability = ClientProfileCardDetails?.Availability;

    if (Availability == "Rotational") {
      changeActiveTab("billable");
    } else {
      changeActiveTab("normal");
    }
  };

  const getClientData = async () => {
    changePageLoadingStatus(true);

    const { activeTab, session } = suiteCareState;
    const { ClientProfileCardDetails } = session;
    let program = ClientProfileCardDetails?.Program;

    let MasterType;
    if (activeTab == "billable") {
      MasterType = "B";
    } else {
      MasterType = "M";
    }

    let requestObj = {
      program: program ? [program] : ["Yodlee"],
      MasterType,
    };

    let ordersMastersByProgram = await BookitApiService.GetAllAvailableMasters(
      requestObj
    );
    //    let ordersMastersByProgram = [
    //     {
    //         "masterId": 365924,
    //         "masterUId": "37977c5d-1657-4504-9b35-1ec2e38528c1",
    //         "communityname": "Test Community",
    //         "suiteno": "SUITE2024",
    //         "suitesize": "2x",
    //         "leasestartdate": "2024-07-06T00:00:00",
    //         "leaseenddate": "2024-09-07T00:00:00",
    //         "moveindate": "2024-07-06T00:00:00",
    //         "moveoutdate": "2024-09-07T00:00:00",
    //         "city": "EL DORADO HILLS, CA",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-08-31T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-18T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-08T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-24T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-23T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 468551,
    //                 "OrderUId": "135dc502-bb04-42c9-887a-b6a913707d76"
    //             },
    //             {
    //                 "DateRequested": "2024-08-03T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-21T00:00:00",
    //                 "IsVendorConfirmed": true,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 468551,
    //                 "OrderUId": "135dc502-bb04-42c9-887a-b6a913707d76"
    //             },
    //             {
    //                 "DateRequested": "2024-07-16T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-20T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-11T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-20T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-06T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-08-15T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-27T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-22T00:00:00",
    //                 "IsVendorConfirmed": true,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 468551,
    //                 "OrderUId": "135dc502-bb04-42c9-887a-b6a913707d76"
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468972,
    //                 "orderUId": "513b8e74-4746-481c-aa41-8a888ee2a2ce",
    //                 "guestfirstname": "Ash",
    //                 "guestlastname": "Test",
    //                 "moveindate": "2024-07-09T00:00:00",
    //                 "moveoutdate": "2024-07-10T00:00:00",
    //                 "orderstatus": "PENDING",
    //                 "br": 0
    //             },
    //             {
    //                 "orderId": 468973,
    //                 "orderUId": "d5956278-75e7-4370-9271-df1f25d29ac9",
    //                 "guestfirstname": "Ashok",
    //                 "guestlastname": "Test",
    //                 "moveindate": "2024-07-13T00:00:00",
    //                 "moveoutdate": "2024-07-15T00:00:00",
    //                 "orderstatus": "PENDING",
    //                 "br": 0
    //             },
    //             {
    //                 "orderId": 468988,
    //                 "orderUId": "f5d641f4-62e3-4d1a-9898-ee74988ae375",
    //                 "guestfirstname": "Ash",
    //                 "guestlastname": "Test",
    //                 "moveindate": "2024-07-17T00:00:00",
    //                 "moveoutdate": "2024-07-19T00:00:00",
    //                 "orderstatus": "PENDING",
    //                 "br": 0
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365924,
    //         "masterUId": "37977c5d-1657-4504-9b35-1ec2e38528c1",
    //         "communityname": "Test Community",
    //         "suiteno": "SUITE2024",
    //         "suitesize": "2x",
    //         "leasestartdate": "2024-07-06T00:00:00",
    //         "leaseenddate": "2024-08-30T00:00:00",
    //         "moveindate": "2024-07-06T00:00:00",
    //         "moveoutdate": "2024-08-30T00:00:00",
    //         "city": "EL DORADO HILLS, CA",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-08-31T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-18T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-08T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-24T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-23T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 468551,
    //                 "OrderUId": "135dc502-bb04-42c9-887a-b6a913707d76"
    //             },
    //             {
    //                 "DateRequested": "2024-08-03T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-21T00:00:00",
    //                 "IsVendorConfirmed": true,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 468551,
    //                 "OrderUId": "135dc502-bb04-42c9-887a-b6a913707d76"
    //             },
    //             {
    //                 "DateRequested": "2024-07-16T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-20T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-11T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-20T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-06T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-08-15T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-27T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-22T00:00:00",
    //                 "IsVendorConfirmed": true,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 468551,
    //                 "OrderUId": "135dc502-bb04-42c9-887a-b6a913707d76"
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468989,
    //                 "orderUId": "4af3dd0c-c10d-4400-889d-067088f56a04",
    //                 "guestfirstname": "Ash",
    //                 "guestlastname": "Test",
    //                 "moveindate": "2024-07-23T00:00:00",
    //                 "moveoutdate": "2024-07-26T00:00:00",
    //                 "orderstatus": "PENDING",
    //                 "br": 0
    //             },
    //             {
    //                 "orderId": 468991,
    //                 "orderUId": "bdb31cd6-134e-4263-8ba6-20584939dbcc",
    //                 "guestfirstname": "",
    //                 "guestlastname": "",
    //                 "moveindate": "2024-07-08T00:00:00",
    //                 "moveoutdate": "2024-07-23T00:00:00",
    //                 "orderstatus": "PENDING",
    //                 "br": 2
    //             },
    //             {
    //                 "orderId": 468551,
    //                 "orderUId": "135dc502-bb04-42c9-887a-b6a913707d76",
    //                 "guestfirstname": "",
    //                 "guestlastname": "",
    //                 "moveindate": "2024-07-08T00:00:00",
    //                 "moveoutdate": "2024-07-21T00:00:00",
    //                 "orderstatus": "PENDING",
    //                 "br": 1
    //             },
    //             {
    //                 "orderId": 468869,
    //                 "orderUId": "bc21d0b9-5e19-4daf-bb1d-c9b7b92a7dc5",
    //                 "guestfirstname": "Ashok",
    //                 "guestlastname": "Tesht",
    //                 "moveindate": "2024-07-30T00:00:00",
    //                 "moveoutdate": "2024-08-02T00:00:00",
    //                 "orderstatus": "PENDING",
    //                 "br": 0
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365740,
    //         "masterUId": "456a9705-7104-480e-9ebc-e3a42451d2b2",
    //         "communityname": "THE PALMS AT BRIARWOOD",
    //         "suiteno": "L304",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-30T00:00:00",
    //         "leaseenddate": "2024-09-30T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "MIDLAND, TX",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468490,
    //                 "orderUId": "2fd30e07-945b-4f68-996d-5d80ab16e620",
    //                 "guestfirstname": "Lazaro",
    //                 "guestlastname": "Armenteros - Family Housing",
    //                 "moveindate": "2024-06-05T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "TERMED",
    //                 "br": 2
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365737,
    //         "masterUId": "924d9579-b876-42bd-bb14-cb6ebd4f9993",
    //         "communityname": "THE PALMS AT BRIARWOOD",
    //         "suiteno": "E305",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-30T00:00:00",
    //         "leaseenddate": "2024-09-30T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "MIDLAND, TX",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": []
    //     },
    //     {
    //         "masterId": 365732,
    //         "masterUId": "316eb04a-206d-4757-872c-ed7d2449fd57",
    //         "communityname": "THE PALMS AT BRIARWOOD",
    //         "suiteno": "L302",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-30T00:00:00",
    //         "leaseenddate": "2024-09-30T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "MIDLAND, TX",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": []
    //     },
    //     {
    //         "masterId": 365711,
    //         "masterUId": "51900cc4-c976-44a7-a0b5-3f0553a50efd",
    //         "communityname": "The Outfield",
    //         "suiteno": "310",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-30T00:00:00",
    //         "leaseenddate": "2024-09-16T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-16T00:00:00",
    //         "city": "LANSING, MI",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468858,
    //                 "orderUId": "4e040821-b9c3-4364-b647-324cd94a82eb",
    //                 "guestfirstname": "Sahid",
    //                 "guestlastname": "Valenzuela",
    //                 "moveindate": "2024-05-31T00:00:00",
    //                 "moveoutdate": "2024-09-16T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 1
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365711,
    //         "masterUId": "51900cc4-c976-44a7-a0b5-3f0553a50efd",
    //         "communityname": "The Outfield",
    //         "suiteno": "310",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-30T00:00:00",
    //         "leaseenddate": "2024-09-16T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-16T00:00:00",
    //         "city": "LANSING, MI",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468857,
    //                 "orderUId": "25ada981-8af4-4d7a-8d92-81f5ddfc59d2",
    //                 "guestfirstname": "Jose",
    //                 "guestlastname": "Escorche",
    //                 "moveindate": "2024-05-31T00:00:00",
    //                 "moveoutdate": "2024-09-16T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 2
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365710,
    //         "masterUId": "b7006cbf-48ab-4dee-9a08-141b87d24416",
    //         "communityname": "The Outfield",
    //         "suiteno": "409",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-30T00:00:00",
    //         "leaseenddate": "2024-09-16T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-16T00:00:00",
    //         "city": "LANSING, MI",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": []
    //     },
    //     {
    //         "masterId": 365710,
    //         "masterUId": "b7006cbf-48ab-4dee-9a08-141b87d24416",
    //         "communityname": "The Outfield",
    //         "suiteno": "409",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-30T00:00:00",
    //         "leaseenddate": "2024-09-16T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-16T00:00:00",
    //         "city": "LANSING, MI",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468860,
    //                 "orderUId": "654bd33b-fe5c-4ae9-8e89-af42ecb78aae",
    //                 "guestfirstname": "Yen Po \"Rambo\" - Staff",
    //                 "guestlastname": "Wang",
    //                 "moveindate": "2024-05-30T00:00:00",
    //                 "moveoutdate": "2024-09-16T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 2
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365690,
    //         "masterUId": "3e6bc819-baf1-4d7e-8e95-dd06997b43da",
    //         "communityname": "Willowbrook Vegas",
    //         "suiteno": "2051",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-23T00:00:00",
    //         "leaseenddate": "2024-09-30T00:00:00",
    //         "moveindate": "2024-03-23T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "LAS VEGAS, NV",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": []
    //     },
    //     {
    //         "masterId": 365689,
    //         "masterUId": "b6c3db47-c96e-4d86-af7d-eb3327922349",
    //         "communityname": "Willowbrook Vegas",
    //         "suiteno": "2045",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-23T00:00:00",
    //         "leaseenddate": "2024-09-30T00:00:00",
    //         "moveindate": "2024-03-23T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "LAS VEGAS, NV",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": []
    //     },
    //     {
    //         "masterId": 365689,
    //         "masterUId": "b6c3db47-c96e-4d86-af7d-eb3327922349",
    //         "communityname": "Willowbrook Vegas",
    //         "suiteno": "2045",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-23T00:00:00",
    //         "leaseenddate": "2024-09-30T00:00:00",
    //         "moveindate": "2024-03-23T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "LAS VEGAS, NV",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": []
    //     },
    //     {
    //         "masterId": 365686,
    //         "masterUId": "1cb16d6b-9487-454f-aa5d-392c672a791f",
    //         "communityname": "Willowbrook Vegas",
    //         "suiteno": "1080",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-23T00:00:00",
    //         "leaseenddate": "2024-09-30T00:00:00",
    //         "moveindate": "2024-03-23T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "LAS VEGAS, NV",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468281,
    //                 "orderUId": "b4051cde-5155-4c14-ac02-acf9b6ffcbf7",
    //                 "guestfirstname": "Royber ",
    //                 "guestlastname": "Salinas",
    //                 "moveindate": "2024-06-19T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 2
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365686,
    //         "masterUId": "1cb16d6b-9487-454f-aa5d-392c672a791f",
    //         "communityname": "Willowbrook Vegas",
    //         "suiteno": "1080",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-23T00:00:00",
    //         "leaseenddate": "2024-09-30T00:00:00",
    //         "moveindate": "2024-03-23T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "LAS VEGAS, NV",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468282,
    //                 "orderUId": "7ccf2073-4233-4d01-9022-2a1e08221052",
    //                 "guestfirstname": "Pedro",
    //                 "guestlastname": "Santos",
    //                 "moveindate": "2024-05-29T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 1
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365685,
    //         "masterUId": "357e27ec-f21b-4ff8-81bd-76e949568f18",
    //         "communityname": "Willowbrook Vegas",
    //         "suiteno": "1071",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-23T00:00:00",
    //         "leaseenddate": "2024-09-30T00:00:00",
    //         "moveindate": "2024-03-23T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "LAS VEGAS, NV",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468274,
    //                 "orderUId": "3b17b5ba-b368-464c-9b23-6d9b8f935328",
    //                 "guestfirstname": "Stevie",
    //                 "guestlastname": "Emmanuels",
    //                 "moveindate": "2024-06-19T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 2
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365685,
    //         "masterUId": "357e27ec-f21b-4ff8-81bd-76e949568f18",
    //         "communityname": "Willowbrook Vegas",
    //         "suiteno": "1071",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-23T00:00:00",
    //         "leaseenddate": "2024-09-30T00:00:00",
    //         "moveindate": "2024-03-23T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "LAS VEGAS, NV",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468275,
    //                 "orderUId": "2ae0bb58-46ca-40a1-bc64-8366ad553423",
    //                 "guestfirstname": "Brett ",
    //                 "guestlastname": "Harris",
    //                 "moveindate": "2024-06-19T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 1
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365684,
    //         "masterUId": "d836d278-74a9-466d-ac6b-b59f2767a0d9",
    //         "communityname": "Willowbrook Vegas",
    //         "suiteno": "2063",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-23T00:00:00",
    //         "leaseenddate": "2024-09-30T00:00:00",
    //         "moveindate": "2024-03-23T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "LAS VEGAS, NV",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468296,
    //                 "orderUId": "8f7a2ca7-89d7-4e35-b018-5a1b06ae8618",
    //                 "guestfirstname": "Coach",
    //                 "guestlastname": "",
    //                 "moveindate": "2024-05-16T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 1
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365683,
    //         "masterUId": "45486c2a-1939-4859-afd5-8107ff4e1d8f",
    //         "communityname": "Willowbrook Vegas",
    //         "suiteno": "1061",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-23T00:00:00",
    //         "leaseenddate": "2024-09-30T00:00:00",
    //         "moveindate": "2024-03-23T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "LAS VEGAS, NV",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": []
    //     },
    //     {
    //         "masterId": 365683,
    //         "masterUId": "45486c2a-1939-4859-afd5-8107ff4e1d8f",
    //         "communityname": "Willowbrook Vegas",
    //         "suiteno": "1061",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-23T00:00:00",
    //         "leaseenddate": "2024-09-30T00:00:00",
    //         "moveindate": "2024-03-23T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "LAS VEGAS, NV",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": []
    //     },
    //     {
    //         "masterId": 365682,
    //         "masterUId": "786677c4-506a-41bb-936a-ab903e6ae55d",
    //         "communityname": "Willowbrook Vegas",
    //         "suiteno": "1051",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-23T00:00:00",
    //         "leaseenddate": "2024-09-30T00:00:00",
    //         "moveindate": "2024-03-23T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "LAS VEGAS, NV",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": []
    //     },
    //     {
    //         "masterId": 365681,
    //         "masterUId": "1d3598c4-0dcc-478a-9ef1-c0ded99c2895",
    //         "communityname": "Willowbrook Vegas",
    //         "suiteno": "1045",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-23T00:00:00",
    //         "leaseenddate": "2024-09-30T00:00:00",
    //         "moveindate": "2024-03-23T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "LAS VEGAS, NV",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": []
    //     },
    //     {
    //         "masterId": 365681,
    //         "masterUId": "1d3598c4-0dcc-478a-9ef1-c0ded99c2895",
    //         "communityname": "Willowbrook Vegas",
    //         "suiteno": "1045",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-23T00:00:00",
    //         "leaseenddate": "2024-09-30T00:00:00",
    //         "moveindate": "2024-03-23T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "LAS VEGAS, NV",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": []
    //     },
    //     {
    //         "masterId": 365680,
    //         "masterUId": "bffa396a-2876-4310-ae3a-5a014454b7d4",
    //         "communityname": "Willowbrook Vegas",
    //         "suiteno": "1038",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-23T00:00:00",
    //         "leaseenddate": "2024-09-30T00:00:00",
    //         "moveindate": "2024-03-23T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "LAS VEGAS, NV",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468252,
    //                 "orderUId": "52a12b82-776c-4efd-92ba-568034cdc2fb",
    //                 "guestfirstname": "Robert ",
    //                 "guestlastname": "Dugger - Family Housing",
    //                 "moveindate": "2024-05-25T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 2
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365680,
    //         "masterUId": "bffa396a-2876-4310-ae3a-5a014454b7d4",
    //         "communityname": "Willowbrook Vegas",
    //         "suiteno": "1038",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-23T00:00:00",
    //         "leaseenddate": "2024-09-30T00:00:00",
    //         "moveindate": "2024-03-23T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "LAS VEGAS, NV",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468253,
    //                 "orderUId": "14190d72-ed0a-4957-8ce7-bc4d3a08c50a",
    //                 "guestfirstname": "Robert ",
    //                 "guestlastname": "Dugger - Family Housing",
    //                 "moveindate": "2024-05-25T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 1
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365679,
    //         "masterUId": "29dd369d-9f25-4e27-82d2-0a0dbf476cc7",
    //         "communityname": "Willowbrook Vegas",
    //         "suiteno": "1030",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-23T00:00:00",
    //         "leaseenddate": "2024-09-30T00:00:00",
    //         "moveindate": "2024-03-23T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "LAS VEGAS, NV",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-15T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-15T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468211,
    //                 "orderUId": "552c4110-016d-4f41-b098-d96b629a19ce",
    //                 "guestfirstname": "Aaron ",
    //                 "guestlastname": "Brooks - Family Housing",
    //                 "moveindate": "2024-05-29T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 1
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365679,
    //         "masterUId": "29dd369d-9f25-4e27-82d2-0a0dbf476cc7",
    //         "communityname": "Willowbrook Vegas",
    //         "suiteno": "1030",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-23T00:00:00",
    //         "leaseenddate": "2024-09-30T00:00:00",
    //         "moveindate": "2024-03-23T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "LAS VEGAS, NV",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-15T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-15T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468210,
    //                 "orderUId": "ccb2d49f-d75b-46c3-a1d1-babcd8ea8fa0",
    //                 "guestfirstname": "Aaron ",
    //                 "guestlastname": "Brooks - Family Housing",
    //                 "moveindate": "2024-05-29T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 2
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365678,
    //         "masterUId": "3611c4d1-555b-4605-8444-13dba24d5e3a",
    //         "communityname": "Willowbrook Vegas",
    //         "suiteno": "1012",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-23T00:00:00",
    //         "leaseenddate": "2024-09-30T00:00:00",
    //         "moveindate": "2024-03-23T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "LAS VEGAS, NV",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468435,
    //                 "orderUId": "74b48ff8-8a6b-4f77-aa13-0d79190b7d5f",
    //                 "guestfirstname": "Colby",
    //                 "guestlastname": "Thomas - Family Housing",
    //                 "moveindate": "2024-06-20T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 2
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365678,
    //         "masterUId": "3611c4d1-555b-4605-8444-13dba24d5e3a",
    //         "communityname": "Willowbrook Vegas",
    //         "suiteno": "1012",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-23T00:00:00",
    //         "leaseenddate": "2024-09-30T00:00:00",
    //         "moveindate": "2024-03-23T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "LAS VEGAS, NV",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468434,
    //                 "orderUId": "a98daec7-5032-4869-8ae6-f6dfaa57874e",
    //                 "guestfirstname": "Colby",
    //                 "guestlastname": "Thomas - Family Housing",
    //                 "moveindate": "2024-06-20T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 1
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365677,
    //         "masterUId": "4cfa7304-be11-4ad6-be5a-224f14fca6f7",
    //         "communityname": "Willowbrook Vegas",
    //         "suiteno": "1011",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-23T00:00:00",
    //         "leaseenddate": "2024-09-30T00:00:00",
    //         "moveindate": "2024-03-23T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "LAS VEGAS, NV",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": []
    //     },
    //     {
    //         "masterId": 365677,
    //         "masterUId": "4cfa7304-be11-4ad6-be5a-224f14fca6f7",
    //         "communityname": "Willowbrook Vegas",
    //         "suiteno": "1011",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-23T00:00:00",
    //         "leaseenddate": "2024-09-30T00:00:00",
    //         "moveindate": "2024-03-23T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "LAS VEGAS, NV",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": []
    //     },
    //     {
    //         "masterId": 365676,
    //         "masterUId": "b1e6af70-27f1-43a3-b4bd-e41f1d572bb8",
    //         "communityname": "Willowbrook Vegas",
    //         "suiteno": "1009",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-23T00:00:00",
    //         "leaseenddate": "2024-09-30T00:00:00",
    //         "moveindate": "2024-03-23T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "LAS VEGAS, NV",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468734,
    //                 "orderUId": "4813f528-d787-4e10-b897-f448ebff5780",
    //                 "guestfirstname": "Austin",
    //                 "guestlastname": "Briggs",
    //                 "moveindate": "2024-06-27T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 2
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365676,
    //         "masterUId": "b1e6af70-27f1-43a3-b4bd-e41f1d572bb8",
    //         "communityname": "Willowbrook Vegas",
    //         "suiteno": "1009",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-23T00:00:00",
    //         "leaseenddate": "2024-09-30T00:00:00",
    //         "moveindate": "2024-03-23T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "LAS VEGAS, NV",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 467815,
    //                 "orderUId": "7975eb77-48d2-4f17-aaf9-144921afb22b",
    //                 "guestfirstname": "Colin",
    //                 "guestlastname": "Peluse",
    //                 "moveindate": "2024-05-10T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 0
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365955,
    //         "masterUId": "c166336f-2fe9-4221-a39f-3c9357cffca9",
    //         "communityname": "Prudden Place",
    //         "suiteno": "02",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-05-06T00:00:00",
    //         "leaseenddate": "2025-05-05T00:00:00",
    //         "moveindate": "2024-05-08T00:00:00",
    //         "moveoutdate": "2024-09-16T00:00:00",
    //         "city": "LANSING, MI",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": []
    //     },
    //     {
    //         "masterId": 365955,
    //         "masterUId": "c166336f-2fe9-4221-a39f-3c9357cffca9",
    //         "communityname": "Prudden Place",
    //         "suiteno": "02",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-05-06T00:00:00",
    //         "leaseenddate": "2025-05-05T00:00:00",
    //         "moveindate": "2024-05-08T00:00:00",
    //         "moveoutdate": "2024-09-16T00:00:00",
    //         "city": "LANSING, MI",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": []
    //     },
    //     {
    //         "masterId": 365924,
    //         "masterUId": "37977c5d-1657-4504-9b35-1ec2e38528c1",
    //         "communityname": "Test Community",
    //         "suiteno": "SUITE2024",
    //         "suitesize": "2x",
    //         "leasestartdate": "2024-04-23T00:00:00",
    //         "leaseenddate": "2024-12-31T00:00:00",
    //         "moveindate": "2024-05-02T00:00:00",
    //         "moveoutdate": "2024-05-08T00:00:00",
    //         "city": "EL DORADO HILLS, CA",
    //         "BR": 0,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-08-31T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-18T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-08T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-24T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-23T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 468551,
    //                 "OrderUId": "135dc502-bb04-42c9-887a-b6a913707d76"
    //             },
    //             {
    //                 "DateRequested": "2024-08-03T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-21T00:00:00",
    //                 "IsVendorConfirmed": true,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 468551,
    //                 "OrderUId": "135dc502-bb04-42c9-887a-b6a913707d76"
    //             },
    //             {
    //                 "DateRequested": "2024-07-16T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-20T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-11T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-20T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-06T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-08-15T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-27T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-22T00:00:00",
    //                 "IsVendorConfirmed": true,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 468551,
    //                 "OrderUId": "135dc502-bb04-42c9-887a-b6a913707d76"
    //             }
    //         ],
    //         "orders": []
    //     },
    //     {
    //         "masterId": 365924,
    //         "masterUId": "37977c5d-1657-4504-9b35-1ec2e38528c1",
    //         "communityname": "Test Community",
    //         "suiteno": "SUITE2024",
    //         "suitesize": "2x",
    //         "leasestartdate": "2024-04-23T00:00:00",
    //         "leaseenddate": "2024-12-31T00:00:00",
    //         "moveindate": "2024-05-02T00:00:00",
    //         "moveoutdate": "2024-05-08T00:00:00",
    //         "city": "EL DORADO HILLS, CA",
    //         "BR": 0,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-08-31T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-18T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-08T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-24T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-23T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 468551,
    //                 "OrderUId": "135dc502-bb04-42c9-887a-b6a913707d76"
    //             },
    //             {
    //                 "DateRequested": "2024-08-03T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-21T00:00:00",
    //                 "IsVendorConfirmed": true,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 468551,
    //                 "OrderUId": "135dc502-bb04-42c9-887a-b6a913707d76"
    //             },
    //             {
    //                 "DateRequested": "2024-07-16T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-20T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-11T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-20T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-06T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-08-15T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-27T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-22T00:00:00",
    //                 "IsVendorConfirmed": true,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 468551,
    //                 "OrderUId": "135dc502-bb04-42c9-887a-b6a913707d76"
    //             }
    //         ],
    //         "orders": []
    //     },
    //     {
    //         "masterId": 365924,
    //         "masterUId": "37977c5d-1657-4504-9b35-1ec2e38528c1",
    //         "communityname": "Test Community",
    //         "suiteno": "SUITE2024",
    //         "suitesize": "2x",
    //         "leasestartdate": "2024-04-23T00:00:00",
    //         "leaseenddate": "2024-12-31T00:00:00",
    //         "moveindate": "2024-09-18T00:00:00",
    //         "moveoutdate": "2024-10-17T00:00:00",
    //         "city": "EL DORADO HILLS, CA",
    //         "BR": 0,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-08-31T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-18T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-08T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-24T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-23T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 468551,
    //                 "OrderUId": "135dc502-bb04-42c9-887a-b6a913707d76"
    //             },
    //             {
    //                 "DateRequested": "2024-08-03T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-21T00:00:00",
    //                 "IsVendorConfirmed": true,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 468551,
    //                 "OrderUId": "135dc502-bb04-42c9-887a-b6a913707d76"
    //             },
    //             {
    //                 "DateRequested": "2024-07-16T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-20T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-11T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-20T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-06T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-08-15T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-27T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-22T00:00:00",
    //                 "IsVendorConfirmed": true,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 468551,
    //                 "OrderUId": "135dc502-bb04-42c9-887a-b6a913707d76"
    //             }
    //         ],
    //         "orders": []
    //     },
    //     {
    //         "masterId": 365924,
    //         "masterUId": "37977c5d-1657-4504-9b35-1ec2e38528c1",
    //         "communityname": "Test Community",
    //         "suiteno": "SUITE2024",
    //         "suitesize": "2x",
    //         "leasestartdate": "2024-04-23T00:00:00",
    //         "leaseenddate": "2024-12-31T00:00:00",
    //         "moveindate": "2024-04-24T00:00:00",
    //         "moveoutdate": "2024-04-30T00:00:00",
    //         "city": "EL DORADO HILLS, CA",
    //         "BR": 0,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-08-31T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-18T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-08T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-24T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-23T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 468551,
    //                 "OrderUId": "135dc502-bb04-42c9-887a-b6a913707d76"
    //             },
    //             {
    //                 "DateRequested": "2024-08-03T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-21T00:00:00",
    //                 "IsVendorConfirmed": true,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 468551,
    //                 "OrderUId": "135dc502-bb04-42c9-887a-b6a913707d76"
    //             },
    //             {
    //                 "DateRequested": "2024-07-16T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-20T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-11T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-20T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-06T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-08-15T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-27T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-22T00:00:00",
    //                 "IsVendorConfirmed": true,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 468551,
    //                 "OrderUId": "135dc502-bb04-42c9-887a-b6a913707d76"
    //             }
    //         ],
    //         "orders": []
    //     },
    //     {
    //         "masterId": 365924,
    //         "masterUId": "37977c5d-1657-4504-9b35-1ec2e38528c1",
    //         "communityname": "Test Community",
    //         "suiteno": "SUITE2024",
    //         "suitesize": "2x",
    //         "leasestartdate": "2024-04-23T00:00:00",
    //         "leaseenddate": "2024-12-31T00:00:00",
    //         "moveindate": "2024-05-02T00:00:00",
    //         "moveoutdate": "2024-05-08T00:00:00",
    //         "city": "EL DORADO HILLS, CA",
    //         "BR": 0,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-08-31T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-18T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-08T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-24T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-23T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 468551,
    //                 "OrderUId": "135dc502-bb04-42c9-887a-b6a913707d76"
    //             },
    //             {
    //                 "DateRequested": "2024-08-03T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-21T00:00:00",
    //                 "IsVendorConfirmed": true,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 468551,
    //                 "OrderUId": "135dc502-bb04-42c9-887a-b6a913707d76"
    //             },
    //             {
    //                 "DateRequested": "2024-07-16T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-20T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-11T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-20T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-06T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-08-15T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-27T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-22T00:00:00",
    //                 "IsVendorConfirmed": true,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 468551,
    //                 "OrderUId": "135dc502-bb04-42c9-887a-b6a913707d76"
    //             }
    //         ],
    //         "orders": []
    //     },
    //     {
    //         "masterId": 365924,
    //         "masterUId": "37977c5d-1657-4504-9b35-1ec2e38528c1",
    //         "communityname": "Test Community",
    //         "suiteno": "SUITE2024",
    //         "suitesize": "2x",
    //         "leasestartdate": "2024-04-23T00:00:00",
    //         "leaseenddate": "2024-12-31T00:00:00",
    //         "moveindate": "2024-06-17T00:00:00",
    //         "moveoutdate": "2024-06-30T00:00:00",
    //         "city": "EL DORADO HILLS, CA",
    //         "BR": 0,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-08-31T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-18T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-08T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-24T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-23T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 468551,
    //                 "OrderUId": "135dc502-bb04-42c9-887a-b6a913707d76"
    //             },
    //             {
    //                 "DateRequested": "2024-08-03T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-21T00:00:00",
    //                 "IsVendorConfirmed": true,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 468551,
    //                 "OrderUId": "135dc502-bb04-42c9-887a-b6a913707d76"
    //             },
    //             {
    //                 "DateRequested": "2024-07-16T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-20T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-11T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-20T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-06T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-08-15T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-27T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-22T00:00:00",
    //                 "IsVendorConfirmed": true,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 468551,
    //                 "OrderUId": "135dc502-bb04-42c9-887a-b6a913707d76"
    //             }
    //         ],
    //         "orders": []
    //     },
    //     {
    //         "masterId": 365924,
    //         "masterUId": "37977c5d-1657-4504-9b35-1ec2e38528c1",
    //         "communityname": "Test Community",
    //         "suiteno": "SUITE2024",
    //         "suitesize": "2x",
    //         "leasestartdate": "2024-04-23T00:00:00",
    //         "leaseenddate": "2024-12-31T00:00:00",
    //         "moveindate": "2024-07-01T00:00:00",
    //         "moveoutdate": "2024-07-05T00:00:00",
    //         "city": "EL DORADO HILLS, CA",
    //         "BR": 0,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-08-31T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-18T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-08T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-24T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-23T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 468551,
    //                 "OrderUId": "135dc502-bb04-42c9-887a-b6a913707d76"
    //             },
    //             {
    //                 "DateRequested": "2024-08-03T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-21T00:00:00",
    //                 "IsVendorConfirmed": true,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 468551,
    //                 "OrderUId": "135dc502-bb04-42c9-887a-b6a913707d76"
    //             },
    //             {
    //                 "DateRequested": "2024-07-16T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-20T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-11T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-20T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-06T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-08-15T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-27T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-07-22T00:00:00",
    //                 "IsVendorConfirmed": true,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 468551,
    //                 "OrderUId": "135dc502-bb04-42c9-887a-b6a913707d76"
    //             }
    //         ],
    //         "orders": []
    //     },
    //     {
    //         "masterId": 365744,
    //         "masterUId": "4da4dd93-be72-4f87-bcfa-c8078a7b23b2",
    //         "communityname": "Gateway Lofts Lansing",
    //         "suiteno": "107",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-04-08T00:00:00",
    //         "leaseenddate": "2024-12-07T00:00:00",
    //         "moveindate": "2024-04-12T00:00:00",
    //         "moveoutdate": "2024-09-16T00:00:00",
    //         "city": "LANSING, MI",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468830,
    //                 "orderUId": "b40f3fd4-3bf2-4d82-abea-d7900f4d6d15",
    //                 "guestfirstname": "Danny",
    //                 "guestlastname": "Bautista",
    //                 "moveindate": "2024-04-17T00:00:00",
    //                 "moveoutdate": "2024-09-16T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 2
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365744,
    //         "masterUId": "4da4dd93-be72-4f87-bcfa-c8078a7b23b2",
    //         "communityname": "Gateway Lofts Lansing",
    //         "suiteno": "107",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-04-08T00:00:00",
    //         "leaseenddate": "2024-12-07T00:00:00",
    //         "moveindate": "2024-04-12T00:00:00",
    //         "moveoutdate": "2024-09-16T00:00:00",
    //         "city": "LANSING, MI",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468831,
    //                 "orderUId": "85ebddd0-a5e2-4e45-ad54-619da5a645c6",
    //                 "guestfirstname": "Garrett",
    //                 "guestlastname": "Irvin",
    //                 "moveindate": "2024-04-12T00:00:00",
    //                 "moveoutdate": "2024-09-16T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 1
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365743,
    //         "masterUId": "0e16b5b9-b7f7-44c4-8995-3a63e704bb37",
    //         "communityname": "Gateway Lofts Lansing",
    //         "suiteno": "531",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-25T00:00:00",
    //         "leaseenddate": "2024-12-24T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-16T00:00:00",
    //         "city": "LANSING, MI",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468841,
    //                 "orderUId": "98a50ea5-0cdf-48d4-a60e-db57bde61633",
    //                 "guestfirstname": "Colton",
    //                 "guestlastname": "Johnson",
    //                 "moveindate": "2024-03-30T00:00:00",
    //                 "moveoutdate": "2024-09-16T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 2
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365743,
    //         "masterUId": "0e16b5b9-b7f7-44c4-8995-3a63e704bb37",
    //         "communityname": "Gateway Lofts Lansing",
    //         "suiteno": "531",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-25T00:00:00",
    //         "leaseenddate": "2024-12-24T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-16T00:00:00",
    //         "city": "LANSING, MI",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468842,
    //                 "orderUId": "a1205e04-2589-432b-b175-1451054aacee",
    //                 "guestfirstname": "Grant",
    //                 "guestlastname": "Judkins",
    //                 "moveindate": "2024-03-30T00:00:00",
    //                 "moveoutdate": "2024-09-16T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 2
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365742,
    //         "masterUId": "5b35b2a1-9213-42ab-8627-7ba7d501a406",
    //         "communityname": "Stadium District Apartments",
    //         "suiteno": "309",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-19T00:00:00",
    //         "leaseenddate": "2025-03-18T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-16T00:00:00",
    //         "city": "LANSING, MI",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468926,
    //                 "orderUId": "7ae302e1-d7aa-441a-8320-4e8d641d1571",
    //                 "guestfirstname": "Jack",
    //                 "guestlastname": "Owen",
    //                 "moveindate": "2024-03-30T00:00:00",
    //                 "moveoutdate": "2024-09-16T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 1
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365742,
    //         "masterUId": "5b35b2a1-9213-42ab-8627-7ba7d501a406",
    //         "communityname": "Stadium District Apartments",
    //         "suiteno": "309",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-19T00:00:00",
    //         "leaseenddate": "2025-03-18T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-16T00:00:00",
    //         "city": "LANSING, MI",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468925,
    //                 "orderUId": "ef288b67-6131-4c58-8492-2c93f64bc313",
    //                 "guestfirstname": "Hunter",
    //                 "guestlastname": "Breault",
    //                 "moveindate": "2024-03-30T00:00:00",
    //                 "moveoutdate": "2024-09-16T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 2
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365741,
    //         "masterUId": "036999d6-48e5-4dbc-869d-ea6814bdcba1",
    //         "communityname": "THE PALMS AT BRIARWOOD",
    //         "suiteno": "L305",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-20T00:00:00",
    //         "leaseenddate": "2024-09-19T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "MIDLAND, TX",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468493,
    //                 "orderUId": "23da544c-ed34-4cb8-8fab-9fc0da62f131",
    //                 "guestfirstname": "Tyler",
    //                 "guestlastname": "Baum",
    //                 "moveindate": "2024-05-10T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 2
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365741,
    //         "masterUId": "036999d6-48e5-4dbc-869d-ea6814bdcba1",
    //         "communityname": "THE PALMS AT BRIARWOOD",
    //         "suiteno": "L305",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-20T00:00:00",
    //         "leaseenddate": "2024-09-19T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "MIDLAND, TX",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468494,
    //                 "orderUId": "54533ab2-b595-4ec8-961a-3c888d7b65b3",
    //                 "guestfirstname": "Colin",
    //                 "guestlastname": "Peluse",
    //                 "moveindate": "2024-03-30T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 1
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365740,
    //         "masterUId": "456a9705-7104-480e-9ebc-e3a42451d2b2",
    //         "communityname": "THE PALMS AT BRIARWOOD",
    //         "suiteno": "L304",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-20T00:00:00",
    //         "leaseenddate": "2024-09-19T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "MIDLAND, TX",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468489,
    //                 "orderUId": "8aec9ceb-c188-4e9d-96ed-02bead22179a",
    //                 "guestfirstname": "Lazaro",
    //                 "guestlastname": "Armenteros - Family Housing",
    //                 "moveindate": "2024-06-05T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 1
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365739,
    //         "masterUId": "873d2965-e7de-4afd-bcae-b4dc44fe0df9",
    //         "communityname": "THE PALMS AT BRIARWOOD",
    //         "suiteno": "L303",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-20T00:00:00",
    //         "leaseenddate": "2024-09-19T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "MIDLAND, TX",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468485,
    //                 "orderUId": "694ccd60-6576-415b-9791-1eed9896ec92",
    //                 "guestfirstname": "Chase",
    //                 "guestlastname": "Cohen",
    //                 "moveindate": "2024-03-30T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 2
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365739,
    //         "masterUId": "873d2965-e7de-4afd-bcae-b4dc44fe0df9",
    //         "communityname": "THE PALMS AT BRIARWOOD",
    //         "suiteno": "L303",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-20T00:00:00",
    //         "leaseenddate": "2024-09-19T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "MIDLAND, TX",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468486,
    //                 "orderUId": "a5c74776-fe28-422b-bcf7-a00d348a6aa3",
    //                 "guestfirstname": "Gunnar ",
    //                 "guestlastname": "Hoglund",
    //                 "moveindate": "2024-03-30T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 1
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365738,
    //         "masterUId": "e9fa9b2a-d11d-4498-a563-a6c9b48e786c",
    //         "communityname": "THE PALMS AT BRIARWOOD",
    //         "suiteno": "L204",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-18T00:00:00",
    //         "leaseenddate": "2024-09-19T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "MIDLAND, TX",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468480,
    //                 "orderUId": "930b55ab-989f-4a7f-a1fc-edb9a1d94b60",
    //                 "guestfirstname": "Wander",
    //                 "guestlastname": "Guante",
    //                 "moveindate": "2024-03-30T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 0
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365738,
    //         "masterUId": "e9fa9b2a-d11d-4498-a563-a6c9b48e786c",
    //         "communityname": "THE PALMS AT BRIARWOOD",
    //         "suiteno": "L204",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-18T00:00:00",
    //         "leaseenddate": "2024-09-19T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "MIDLAND, TX",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468481,
    //                 "orderUId": "33d72916-6b7d-4789-8f01-8aa6cd197133",
    //                 "guestfirstname": "Jose",
    //                 "guestlastname": "Mujica",
    //                 "moveindate": "2024-03-30T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 1
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365737,
    //         "masterUId": "924d9579-b876-42bd-bb14-cb6ebd4f9993",
    //         "communityname": "THE PALMS AT BRIARWOOD",
    //         "suiteno": "E305",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-18T00:00:00",
    //         "leaseenddate": "2024-09-19T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "MIDLAND, TX",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468472,
    //                 "orderUId": "378dd171-bf59-4981-81da-1c72495a2390",
    //                 "guestfirstname": "Jack ",
    //                 "guestlastname": "Winkler",
    //                 "moveindate": "2024-03-30T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 2
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365736,
    //         "masterUId": "0542bf72-9664-400c-be94-84a421653c38",
    //         "communityname": "THE PALMS AT BRIARWOOD",
    //         "suiteno": "E304",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-18T00:00:00",
    //         "leaseenddate": "2024-09-20T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "MIDLAND, TX",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468456,
    //                 "orderUId": "fb868ab6-24ba-4253-bc6a-653ad2862a62",
    //                 "guestfirstname": "Brennan",
    //                 "guestlastname": "Milone",
    //                 "moveindate": "2024-03-30T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 1
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365736,
    //         "masterUId": "0542bf72-9664-400c-be94-84a421653c38",
    //         "communityname": "THE PALMS AT BRIARWOOD",
    //         "suiteno": "E304",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-18T00:00:00",
    //         "leaseenddate": "2024-09-20T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "MIDLAND, TX",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468454,
    //                 "orderUId": "86bb339e-81e8-4299-af92-32b854c99f43",
    //                 "guestfirstname": "Caeden ",
    //                 "guestlastname": "Trenkle",
    //                 "moveindate": "2024-03-30T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 2
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365735,
    //         "masterUId": "2b70b881-e70f-40d1-81db-ae5c322321d4",
    //         "communityname": "THE PALMS AT BRIARWOOD",
    //         "suiteno": "D203",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-15T00:00:00",
    //         "leaseenddate": "2024-09-19T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "MIDLAND, TX",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468443,
    //                 "orderUId": "4c155ea9-a079-410b-867e-4fe49ed31834",
    //                 "guestfirstname": "Micah",
    //                 "guestlastname": "Dallas",
    //                 "moveindate": "2024-05-10T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 2
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365735,
    //         "masterUId": "2b70b881-e70f-40d1-81db-ae5c322321d4",
    //         "communityname": "THE PALMS AT BRIARWOOD",
    //         "suiteno": "D203",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-15T00:00:00",
    //         "leaseenddate": "2024-09-19T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "MIDLAND, TX",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468444,
    //                 "orderUId": "fd41358f-0b38-4e89-aa5d-ab50f5c03726",
    //                 "guestfirstname": "David",
    //                 "guestlastname": "Leal",
    //                 "moveindate": "2024-03-30T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 1
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365734,
    //         "masterUId": "c66456ed-65ae-4ea3-983d-0438e7402ab9",
    //         "communityname": "THE PALMS AT BRIARWOOD",
    //         "suiteno": "C306",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-15T00:00:00",
    //         "leaseenddate": "2024-09-19T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "MIDLAND, TX",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468438,
    //                 "orderUId": "138b6595-5407-480e-8426-da3ef4e299bb",
    //                 "guestfirstname": "Ryan",
    //                 "guestlastname": "Cusick",
    //                 "moveindate": "2024-05-24T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 1
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365734,
    //         "masterUId": "c66456ed-65ae-4ea3-983d-0438e7402ab9",
    //         "communityname": "THE PALMS AT BRIARWOOD",
    //         "suiteno": "C306",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-15T00:00:00",
    //         "leaseenddate": "2024-09-19T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "MIDLAND, TX",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468436,
    //                 "orderUId": "5d0d2764-f716-4a61-9d34-f26a1175b866",
    //                 "guestfirstname": "Blake",
    //                 "guestlastname": "Beers",
    //                 "moveindate": "2024-03-30T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 2
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365733,
    //         "masterUId": "5da2cd03-af2a-48a0-9374-1adc580eb326",
    //         "communityname": "THE PALMS AT BRIARWOOD",
    //         "suiteno": "L307",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-15T00:00:00",
    //         "leaseenddate": "2024-09-19T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "MIDLAND, TX",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468496,
    //                 "orderUId": "cdb684c5-ee9a-4524-9676-5fc4cfe6e607",
    //                 "guestfirstname": "Sahid",
    //                 "guestlastname": "Valenzuela",
    //                 "moveindate": "2024-05-01T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 2
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365733,
    //         "masterUId": "5da2cd03-af2a-48a0-9374-1adc580eb326",
    //         "communityname": "THE PALMS AT BRIARWOOD",
    //         "suiteno": "L307",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-15T00:00:00",
    //         "leaseenddate": "2024-09-19T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "MIDLAND, TX",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468498,
    //                 "orderUId": "7be149a0-ff52-441b-85f9-c6c3b1e171e2",
    //                 "guestfirstname": "James",
    //                 "guestlastname": "Gonzalez",
    //                 "moveindate": "2024-05-01T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 1
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365732,
    //         "masterUId": "316eb04a-206d-4757-872c-ed7d2449fd57",
    //         "communityname": "THE PALMS AT BRIARWOOD",
    //         "suiteno": "L302",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-20T00:00:00",
    //         "leaseenddate": "2024-09-19T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "MIDLAND, TX",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468483,
    //                 "orderUId": "789d2369-bab7-46f2-b34a-1d895f2aa9b0",
    //                 "guestfirstname": "Seth",
    //                 "guestlastname": "Elledge",
    //                 "moveindate": "2024-04-28T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 2
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365731,
    //         "masterUId": "a4a5a5bd-244f-4212-b442-ab7e99fb88eb",
    //         "communityname": "THE PALMS AT BRIARWOOD",
    //         "suiteno": "L202",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-20T00:00:00",
    //         "leaseenddate": "2024-09-19T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "MIDLAND, TX",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468479,
    //                 "orderUId": "9cff0227-90ac-4b5b-aa24-057123033b44",
    //                 "guestfirstname": "Austin",
    //                 "guestlastname": "Briggs",
    //                 "moveindate": "2024-03-30T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 1
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365731,
    //         "masterUId": "a4a5a5bd-244f-4212-b442-ab7e99fb88eb",
    //         "communityname": "THE PALMS AT BRIARWOOD",
    //         "suiteno": "L202",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-20T00:00:00",
    //         "leaseenddate": "2024-09-19T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "MIDLAND, TX",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468478,
    //                 "orderUId": "5f9334d7-e057-402a-a7d9-36c5e35d61af",
    //                 "guestfirstname": "Jack ",
    //                 "guestlastname": "Cushing",
    //                 "moveindate": "2024-03-30T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 2
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365730,
    //         "masterUId": "dc8a7de3-d0f1-4595-ad5b-c72e0b5c0957",
    //         "communityname": "THE PALMS AT BRIARWOOD",
    //         "suiteno": "F308",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-20T00:00:00",
    //         "leaseenddate": "2024-09-19T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "MIDLAND, TX",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468477,
    //                 "orderUId": "d23d13d5-e814-455d-b88c-8fc4085e774e",
    //                 "guestfirstname": "Junior ",
    //                 "guestlastname": "Perez",
    //                 "moveindate": "2024-03-30T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 1
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365730,
    //         "masterUId": "dc8a7de3-d0f1-4595-ad5b-c72e0b5c0957",
    //         "communityname": "THE PALMS AT BRIARWOOD",
    //         "suiteno": "F308",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-20T00:00:00",
    //         "leaseenddate": "2024-09-19T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "MIDLAND, TX",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468476,
    //                 "orderUId": "c8d8a5f5-8780-45b6-b9e6-42046e888684",
    //                 "guestfirstname": "Jamaliel",
    //                 "guestlastname": "Rosado",
    //                 "moveindate": "2024-06-05T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 2
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365729,
    //         "masterUId": "538b7770-7760-4929-ac76-f3c451a6425b",
    //         "communityname": "THE PALMS AT BRIARWOOD",
    //         "suiteno": "E302",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-20T00:00:00",
    //         "leaseenddate": "2024-09-19T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "MIDLAND, TX",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468453,
    //                 "orderUId": "abf399a5-2d66-43cc-a552-7f93048e2d3a",
    //                 "guestfirstname": "Shohei",
    //                 "guestlastname": "Tomioka",
    //                 "moveindate": "2024-03-30T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 1
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365729,
    //         "masterUId": "538b7770-7760-4929-ac76-f3c451a6425b",
    //         "communityname": "THE PALMS AT BRIARWOOD",
    //         "suiteno": "E302",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-20T00:00:00",
    //         "leaseenddate": "2024-09-19T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "MIDLAND, TX",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468451,
    //                 "orderUId": "de8d1c1c-8748-4dab-aca6-6f48683227b9",
    //                 "guestfirstname": "Denzel",
    //                 "guestlastname": "Clarke",
    //                 "moveindate": "2024-03-30T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 2
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365728,
    //         "masterUId": "00b28432-d6c2-4cab-b770-24df50297552",
    //         "communityname": "THE PALMS AT BRIARWOOD",
    //         "suiteno": "E201",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-20T00:00:00",
    //         "leaseenddate": "2024-09-19T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "MIDLAND, TX",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468448,
    //                 "orderUId": "82919b06-6e1f-4a13-8728-962f76fe1040",
    //                 "guestfirstname": "Daniel",
    //                 "guestlastname": "Susac",
    //                 "moveindate": "2024-03-30T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 2
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365728,
    //         "masterUId": "00b28432-d6c2-4cab-b770-24df50297552",
    //         "communityname": "THE PALMS AT BRIARWOOD",
    //         "suiteno": "E201",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-20T00:00:00",
    //         "leaseenddate": "2024-09-19T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "MIDLAND, TX",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468450,
    //                 "orderUId": "366ebd27-bbe3-49eb-a99e-d0e2d2602ea0",
    //                 "guestfirstname": "Colby",
    //                 "guestlastname": "Thomas",
    //                 "moveindate": "2024-03-30T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 1
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365727,
    //         "masterUId": "caab6cc2-e4f8-4420-9a7b-2701bb27a1b0",
    //         "communityname": "THE PALMS AT BRIARWOOD",
    //         "suiteno": "D301",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-20T00:00:00",
    //         "leaseenddate": "2024-09-21T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "MIDLAND, TX",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468447,
    //                 "orderUId": "5d4887e9-3952-42ba-8165-494a74a60cbe",
    //                 "guestfirstname": "Jeremy",
    //                 "guestlastname": "Eierman",
    //                 "moveindate": "2024-05-10T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 1
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365727,
    //         "masterUId": "caab6cc2-e4f8-4420-9a7b-2701bb27a1b0",
    //         "communityname": "THE PALMS AT BRIARWOOD",
    //         "suiteno": "D301",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-20T00:00:00",
    //         "leaseenddate": "2024-09-21T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "MIDLAND, TX",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468445,
    //                 "orderUId": "8198ef48-74c7-4be9-bb2a-b2638080457d",
    //                 "guestfirstname": "Jack",
    //                 "guestlastname": "Perkins",
    //                 "moveindate": "2024-03-30T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 2
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365726,
    //         "masterUId": "da160775-8559-438c-867a-955bb3dc4c39",
    //         "communityname": "THE PALMS AT BRIARWOOD",
    //         "suiteno": "D201",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-20T00:00:00",
    //         "leaseenddate": "2024-09-19T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "MIDLAND, TX",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468439,
    //                 "orderUId": "0a040487-d78b-4d2a-b8ad-4919eaa3d730",
    //                 "guestfirstname": "Shane",
    //                 "guestlastname": "Mcguire",
    //                 "moveindate": "2024-03-30T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 2
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365726,
    //         "masterUId": "da160775-8559-438c-867a-955bb3dc4c39",
    //         "communityname": "THE PALMS AT BRIARWOOD",
    //         "suiteno": "D201",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-20T00:00:00",
    //         "leaseenddate": "2024-09-19T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "MIDLAND, TX",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468441,
    //                 "orderUId": "65b8e601-f065-430b-8bce-be148e6d5f70",
    //                 "guestfirstname": "Cooper ",
    //                 "guestlastname": "Bowman",
    //                 "moveindate": "2024-03-30T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 1
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365723,
    //         "masterUId": "db762eae-24a5-473b-aa55-eef33b36d5a9",
    //         "communityname": "Marketplace",
    //         "suiteno": "304",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-20T00:00:00",
    //         "leaseenddate": "2025-03-19T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-16T00:00:00",
    //         "city": "LANSING, MI",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468854,
    //                 "orderUId": "ce188d5e-447a-43c6-b97f-1ef6310f2587",
    //                 "guestfirstname": "Chen",
    //                 "guestlastname": "Zhuang",
    //                 "moveindate": "2024-05-30T00:00:00",
    //                 "moveoutdate": "2024-09-16T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 2
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365723,
    //         "masterUId": "db762eae-24a5-473b-aa55-eef33b36d5a9",
    //         "communityname": "Marketplace",
    //         "suiteno": "304",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-20T00:00:00",
    //         "leaseenddate": "2025-03-19T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-16T00:00:00",
    //         "city": "LANSING, MI",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468855,
    //                 "orderUId": "2ebebedb-31cd-4118-8401-9a364da7cc72",
    //                 "guestfirstname": "Yehizon",
    //                 "guestlastname": "Sanchez",
    //                 "moveindate": "2024-03-30T00:00:00",
    //                 "moveoutdate": "2024-09-16T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 1
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365722,
    //         "masterUId": "f00e5103-f33a-42cd-b6a6-a428cb6100a8",
    //         "communityname": "Stadium District Apartments",
    //         "suiteno": "219",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-19T00:00:00",
    //         "leaseenddate": "2025-03-18T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-16T00:00:00",
    //         "city": "LANSING, MI",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468868,
    //                 "orderUId": "c393107e-1a2e-4fab-ae11-a197d27c67dd",
    //                 "guestfirstname": "Casey",
    //                 "guestlastname": "Yamauchi",
    //                 "moveindate": "2024-04-30T00:00:00",
    //                 "moveoutdate": "2024-09-16T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 1
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365722,
    //         "masterUId": "f00e5103-f33a-42cd-b6a6-a428cb6100a8",
    //         "communityname": "Stadium District Apartments",
    //         "suiteno": "219",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-19T00:00:00",
    //         "leaseenddate": "2025-03-18T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-16T00:00:00",
    //         "city": "LANSING, MI",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468865,
    //                 "orderUId": "617af8fd-8775-44f6-9e9e-2f803ad237be",
    //                 "guestfirstname": "Euribiel",
    //                 "guestlastname": "Angeles",
    //                 "moveindate": "2024-03-30T00:00:00",
    //                 "moveoutdate": "2024-09-16T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 2
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365721,
    //         "masterUId": "861be4e0-a557-45ce-857a-5dfc8d49a3b5",
    //         "communityname": "Stadium District Apartments",
    //         "suiteno": "416",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-18T00:00:00",
    //         "leaseenddate": "2025-03-19T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-16T00:00:00",
    //         "city": "LANSING, MI",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468928,
    //                 "orderUId": "927366ed-c4c7-4ddf-adac-0ed9225e04af",
    //                 "guestfirstname": "Cj",
    //                 "guestlastname": "Rodriguez",
    //                 "moveindate": "2024-03-30T00:00:00",
    //                 "moveoutdate": "2024-09-16T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 1
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365721,
    //         "masterUId": "861be4e0-a557-45ce-857a-5dfc8d49a3b5",
    //         "communityname": "Stadium District Apartments",
    //         "suiteno": "416",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-18T00:00:00",
    //         "leaseenddate": "2025-03-19T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-16T00:00:00",
    //         "city": "LANSING, MI",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468927,
    //                 "orderUId": "c72754da-4b84-42cf-b537-6d75eae509ab",
    //                 "guestfirstname": "Cameron",
    //                 "guestlastname": "Masterman",
    //                 "moveindate": "2024-03-30T00:00:00",
    //                 "moveoutdate": "2024-09-16T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 2
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365716,
    //         "masterUId": "7d50d850-2c57-4939-a440-c6c203d66707",
    //         "communityname": "Gateway Lofts Lansing",
    //         "suiteno": "519",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-06T00:00:00",
    //         "leaseenddate": "2024-10-05T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-16T00:00:00",
    //         "city": "LANSING, MI",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468839,
    //                 "orderUId": "fd069a33-94a5-4e44-bb65-7e084a37ff84",
    //                 "guestfirstname": "Jonny",
    //                 "guestlastname": "Butler",
    //                 "moveindate": "2024-03-30T00:00:00",
    //                 "moveoutdate": "2024-09-16T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 2
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365716,
    //         "masterUId": "7d50d850-2c57-4939-a440-c6c203d66707",
    //         "communityname": "Gateway Lofts Lansing",
    //         "suiteno": "519",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-06T00:00:00",
    //         "leaseenddate": "2024-10-05T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-16T00:00:00",
    //         "city": "LANSING, MI",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468840,
    //                 "orderUId": "60c80ca7-10fa-4091-81d6-d21532da9688",
    //                 "guestfirstname": "Jake",
    //                 "guestlastname": "Garland",
    //                 "moveindate": "2024-03-30T00:00:00",
    //                 "moveoutdate": "2024-09-16T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 1
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365713,
    //         "masterUId": "32ba54f7-9d8d-4b41-a7d4-9d16dfec73ca",
    //         "communityname": "Marketplace",
    //         "suiteno": "227",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-15T00:00:00",
    //         "leaseenddate": "2024-09-09T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-16T00:00:00",
    //         "city": "LANSING, MI",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468850,
    //                 "orderUId": "d688cf02-fec0-4d9c-bc1a-2d1a8e676f0f",
    //                 "guestfirstname": "Luke",
    //                 "guestlastname": "Mann",
    //                 "moveindate": "2024-04-30T00:00:00",
    //                 "moveoutdate": "2024-09-16T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 2
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365713,
    //         "masterUId": "32ba54f7-9d8d-4b41-a7d4-9d16dfec73ca",
    //         "communityname": "Marketplace",
    //         "suiteno": "227",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-15T00:00:00",
    //         "leaseenddate": "2024-09-09T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-16T00:00:00",
    //         "city": "LANSING, MI",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468852,
    //                 "orderUId": "7a9e668a-3fe1-405d-9fb5-08ab8ad88d64",
    //                 "guestfirstname": "Cole ",
    //                 "guestlastname": "Conn",
    //                 "moveindate": "2024-05-11T00:00:00",
    //                 "moveoutdate": "2024-09-16T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 1
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365712,
    //         "masterUId": "932ffa20-551a-4c00-bb75-0c692227c759",
    //         "communityname": "Marketplace",
    //         "suiteno": "217",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-15T00:00:00",
    //         "leaseenddate": "2024-09-09T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-16T00:00:00",
    //         "city": "LANSING, MI",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468846,
    //                 "orderUId": "9ff38fcd-6056-40fe-af90-c72261f0def0",
    //                 "guestfirstname": "Luis",
    //                 "guestlastname": "Morales",
    //                 "moveindate": "2024-04-30T00:00:00",
    //                 "moveoutdate": "2024-09-16T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 2
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365712,
    //         "masterUId": "932ffa20-551a-4c00-bb75-0c692227c759",
    //         "communityname": "Marketplace",
    //         "suiteno": "217",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-15T00:00:00",
    //         "leaseenddate": "2024-09-09T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-16T00:00:00",
    //         "city": "LANSING, MI",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468848,
    //                 "orderUId": "33cd24b1-c803-4f20-b011-2429a0dc47b4",
    //                 "guestfirstname": "Brayan",
    //                 "guestlastname": "Buelvas",
    //                 "moveindate": "2024-03-30T00:00:00",
    //                 "moveoutdate": "2024-09-16T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 1
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365709,
    //         "masterUId": "7b2dca88-2a0f-490a-9e4f-c8d33fabdccb",
    //         "communityname": "Stadium District Apartments",
    //         "suiteno": "306",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-20T00:00:00",
    //         "leaseenddate": "2024-09-09T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-16T00:00:00",
    //         "city": "LANSING, MI",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468922,
    //                 "orderUId": "77a16895-36a8-45b6-864b-4e8c8e6bae1b",
    //                 "guestfirstname": "Mitch",
    //                 "guestlastname": "Myers",
    //                 "moveindate": "2024-03-30T00:00:00",
    //                 "moveoutdate": "2024-09-16T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 2
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365709,
    //         "masterUId": "7b2dca88-2a0f-490a-9e4f-c8d33fabdccb",
    //         "communityname": "Stadium District Apartments",
    //         "suiteno": "306",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-20T00:00:00",
    //         "leaseenddate": "2024-09-09T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-16T00:00:00",
    //         "city": "LANSING, MI",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468924,
    //                 "orderUId": "d5e27fd2-368a-4eed-8ff1-842f81b9cf5e",
    //                 "guestfirstname": "Jacob",
    //                 "guestlastname": "Watters",
    //                 "moveindate": "2024-03-30T00:00:00",
    //                 "moveoutdate": "2024-09-16T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 1
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365708,
    //         "masterUId": "3252b37f-9ae7-46c1-9ed8-b6b8c4a5e83c",
    //         "communityname": "Stadium District Apartments",
    //         "suiteno": "216",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-19T00:00:00",
    //         "leaseenddate": "2024-09-09T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-16T00:00:00",
    //         "city": "LANSING, MI",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468862,
    //                 "orderUId": "4d78d5ac-bc46-4d8b-98fb-326d0f98b598",
    //                 "guestfirstname": "Luis ",
    //                 "guestlastname": "Carrasco - Family Housing",
    //                 "moveindate": "2024-03-30T00:00:00",
    //                 "moveoutdate": "2024-09-16T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 2
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365708,
    //         "masterUId": "3252b37f-9ae7-46c1-9ed8-b6b8c4a5e83c",
    //         "communityname": "Stadium District Apartments",
    //         "suiteno": "216",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-19T00:00:00",
    //         "leaseenddate": "2024-09-09T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-16T00:00:00",
    //         "city": "LANSING, MI",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468864,
    //                 "orderUId": "6eb97ad7-4de3-4dd4-8824-0112969d9360",
    //                 "guestfirstname": "Luis",
    //                 "guestlastname": "Carrasco - Family Housing",
    //                 "moveindate": "2024-03-30T00:00:00",
    //                 "moveoutdate": "2024-09-16T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 1
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365690,
    //         "masterUId": "3e6bc819-baf1-4d7e-8e95-dd06997b43da",
    //         "communityname": "Willowbrook Vegas",
    //         "suiteno": "2051",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-15T00:00:00",
    //         "leaseenddate": "2024-09-14T00:00:00",
    //         "moveindate": "2024-03-23T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "LAS VEGAS, NV",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468292,
    //                 "orderUId": "fd638144-c5c0-49ce-a6d8-b0b83b22e8ae",
    //                 "guestfirstname": "Logan ",
    //                 "guestlastname": "Davidson",
    //                 "moveindate": "2024-03-23T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 1
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365688,
    //         "masterUId": "c0d6e9d5-ba91-431b-a84a-82ee0194687c",
    //         "communityname": "Willowbrook Vegas",
    //         "suiteno": "2038",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-15T00:00:00",
    //         "leaseenddate": "2024-09-14T00:00:00",
    //         "moveindate": "2024-03-23T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "LAS VEGAS, NV",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468291,
    //                 "orderUId": "c06d7174-9467-4a69-8154-02ef5f109a3c",
    //                 "guestfirstname": "Grant",
    //                 "guestlastname": "Holman",
    //                 "moveindate": "2024-06-06T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 1
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365688,
    //         "masterUId": "c0d6e9d5-ba91-431b-a84a-82ee0194687c",
    //         "communityname": "Willowbrook Vegas",
    //         "suiteno": "2038",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-15T00:00:00",
    //         "leaseenddate": "2024-09-14T00:00:00",
    //         "moveindate": "2024-03-23T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "LAS VEGAS, NV",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468289,
    //                 "orderUId": "769f7a6c-8306-434b-bd33-b96777d30087",
    //                 "guestfirstname": "Brady",
    //                 "guestlastname": "Basso",
    //                 "moveindate": "2024-05-24T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 2
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365687,
    //         "masterUId": "03722a2e-1a15-46ad-87a5-4a18bb1a60b1",
    //         "communityname": "Willowbrook Vegas",
    //         "suiteno": "2012",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-15T00:00:00",
    //         "leaseenddate": "2024-09-14T00:00:00",
    //         "moveindate": "2024-03-23T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "LAS VEGAS, NV",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468284,
    //                 "orderUId": "8ad4de64-21ee-4be4-a522-48d5faff71f8",
    //                 "guestfirstname": "Quincy",
    //                 "guestlastname": "Nieporte",
    //                 "moveindate": "2024-05-25T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 2
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365687,
    //         "masterUId": "03722a2e-1a15-46ad-87a5-4a18bb1a60b1",
    //         "communityname": "Willowbrook Vegas",
    //         "suiteno": "2012",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-15T00:00:00",
    //         "leaseenddate": "2024-09-14T00:00:00",
    //         "moveindate": "2024-03-23T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "LAS VEGAS, NV",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468286,
    //                 "orderUId": "82251ef7-6e30-4c70-91a6-57552019b537",
    //                 "guestfirstname": "Jt",
    //                 "guestlastname": "Ginn",
    //                 "moveindate": "2024-05-25T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 1
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365684,
    //         "masterUId": "d836d278-74a9-466d-ac6b-b59f2767a0d9",
    //         "communityname": "Willowbrook Vegas",
    //         "suiteno": "2063",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-15T00:00:00",
    //         "leaseenddate": "2024-09-14T00:00:00",
    //         "moveindate": "2024-03-23T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "LAS VEGAS, NV",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468295,
    //                 "orderUId": "59d8004d-d658-46fa-a852-8831fba8c312",
    //                 "guestfirstname": "Coach",
    //                 "guestlastname": "",
    //                 "moveindate": "2024-05-17T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 2
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365682,
    //         "masterUId": "786677c4-506a-41bb-936a-ab903e6ae55d",
    //         "communityname": "Willowbrook Vegas",
    //         "suiteno": "1051",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-15T00:00:00",
    //         "leaseenddate": "2024-09-14T00:00:00",
    //         "moveindate": "2024-03-23T00:00:00",
    //         "moveoutdate": "2024-09-30T00:00:00",
    //         "city": "LAS VEGAS, NV",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-10-01T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468266,
    //                 "orderUId": "b390ff37-7934-4c98-b45f-b95073180c2b",
    //                 "guestfirstname": "Joe",
    //                 "guestlastname": "Boyle",
    //                 "moveindate": "2024-03-23T00:00:00",
    //                 "moveoutdate": "2024-09-30T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 2
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365666,
    //         "masterUId": "be012287-c997-47c9-8237-64a55546cb34",
    //         "communityname": "Gateway Lofts Lansing",
    //         "suiteno": "231",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-06T00:00:00",
    //         "leaseenddate": "2024-10-05T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-16T00:00:00",
    //         "city": "LANSING, MI",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468838,
    //                 "orderUId": "bae3c4c4-ad9a-4106-b82e-4dc43848488a",
    //                 "guestfirstname": "Blaze",
    //                 "guestlastname": "Pontes",
    //                 "moveindate": "2024-03-30T00:00:00",
    //                 "moveoutdate": "2024-09-16T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 1
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365666,
    //         "masterUId": "be012287-c997-47c9-8237-64a55546cb34",
    //         "communityname": "Gateway Lofts Lansing",
    //         "suiteno": "231",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-06T00:00:00",
    //         "leaseenddate": "2024-10-05T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-16T00:00:00",
    //         "city": "LANSING, MI",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468837,
    //                 "orderUId": "e296aa34-eb48-4ddf-a7ce-5ea219c3274f",
    //                 "guestfirstname": "Will",
    //                 "guestlastname": "Johnston",
    //                 "moveindate": "2024-04-30T00:00:00",
    //                 "moveoutdate": "2024-09-16T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 2
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365665,
    //         "masterUId": "7ce5a9af-d130-4da6-aea8-7ac2ac6768ac",
    //         "communityname": "Gateway Lofts Lansing",
    //         "suiteno": "112",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-06T00:00:00",
    //         "leaseenddate": "2024-10-05T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-16T00:00:00",
    //         "city": "LANSING, MI",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468834,
    //                 "orderUId": "b1903fdb-a14a-407e-af98-3cdf90c9edac",
    //                 "guestfirstname": "Colby ",
    //                 "guestlastname": "Halter",
    //                 "moveindate": "2024-03-30T00:00:00",
    //                 "moveoutdate": "2024-09-16T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 2
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365665,
    //         "masterUId": "7ce5a9af-d130-4da6-aea8-7ac2ac6768ac",
    //         "communityname": "Gateway Lofts Lansing",
    //         "suiteno": "112",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-06T00:00:00",
    //         "leaseenddate": "2024-10-05T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-16T00:00:00",
    //         "city": "LANSING, MI",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468835,
    //                 "orderUId": "d3a1c49c-a63d-4081-8512-9ba890394ad2",
    //                 "guestfirstname": "Dylan ",
    //                 "guestlastname": "Hall",
    //                 "moveindate": "2024-03-30T00:00:00",
    //                 "moveoutdate": "2024-09-16T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 1
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365664,
    //         "masterUId": "56efd0f6-8c30-419c-8d64-9173628853dc",
    //         "communityname": "Gateway Lofts Lansing",
    //         "suiteno": "103",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-06T00:00:00",
    //         "leaseenddate": "2024-10-05T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-16T00:00:00",
    //         "city": "LANSING, MI",
    //         "BR": 1,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468827,
    //                 "orderUId": "5b3a7565-5c97-402c-a66e-227eda9fe543",
    //                 "guestfirstname": "Will",
    //                 "guestlastname": "Simpson",
    //                 "moveindate": "2024-03-30T00:00:00",
    //                 "moveoutdate": "2024-09-16T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 1
    //             }
    //         ]
    //     },
    //     {
    //         "masterId": 365664,
    //         "masterUId": "56efd0f6-8c30-419c-8d64-9173628853dc",
    //         "communityname": "Gateway Lofts Lansing",
    //         "suiteno": "103",
    //         "suitesize": "2X2",
    //         "leasestartdate": "2024-03-06T00:00:00",
    //         "leaseenddate": "2024-10-05T00:00:00",
    //         "moveindate": "2024-03-30T00:00:00",
    //         "moveoutdate": "2024-09-16T00:00:00",
    //         "city": "LANSING, MI",
    //         "BR": 2,
    //         "schedules": [
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": false,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             },
    //             {
    //                 "DateRequested": "2024-09-17T00:00:00",
    //                 "IsVendorConfirmed": null,
    //                 "StatusCode": null,
    //                 "TypeCode": "T/O",
    //                 "TableId": 0,
    //                 "OrderUId": null
    //             }
    //         ],
    //         "orders": [
    //             {
    //                 "orderId": 468532,
    //                 "orderUId": "58bd55ca-6894-4d1f-bc82-152a45ebb53c",
    //                 "guestfirstname": "Henry",
    //                 "guestlastname": "Bolte",
    //                 "moveindate": "2024-03-30T00:00:00",
    //                 "moveoutdate": "2024-09-16T00:00:00",
    //                 "orderstatus": "ACTIVE",
    //                 "br": 2
    //             }
    //         ]
    //     }
    // ];
    updateState({ ordersMastersByProgram: ordersMastersByProgram });

    // aggregate data
    let aggregatedMastersData;
    if (activeTab == "billable") {
      aggregatedMastersData = groupAndAggregateBillableData(
        ordersMastersByProgram
      );
    } else {
      aggregatedMastersData = groupAndAggregateNormalData(
        ordersMastersByProgram
      );
    }
    updateState({
      aggregatedMastersData,
      aggregatedMastersDataFiltered: JSON.parse(
        JSON.stringify(aggregatedMastersData)
      ),
    });

    // filter unique cities from aggregated data
    const cities = uniqueCities(aggregatedMastersData);
    updateState({ cities });

    changePageLoadingStatus(false);
  };

  const selectCity = (city) => {
    updateState({ selectedCity: city });
  };

  const changeSearchtext = (text) => {
    text = text.toLowerCase();
    updateState({ searchedText: text });
  };

  const openScheduleModal = (schedule) => {
    $(function () {
      $("#schedule-modal").modal("show");
    });
    updateState({ selectedSchedule: JSON.parse(JSON.stringify(schedule)) });
  };

  const openNewReservationModal = (masterData) => {
    $(document).ready(function () {
      // console.log("hello");
      jQuery(function ($) {
        $("#phoneNumber").intlTelInput("setCountry", "us");
      });
    });
    $(function () {
      $("#createNewReservation").modal("show");
    });
    updateState({ selectedMasterForReservation: masterData });
  };

  const openOrderDetailsModal = (order, master) => {
    $(function () {
      $("#orderDetailsPopUp").modal("show");
    });
    updateState({
      selectedOrder: JSON.parse(order),
      selectedMasterForOrderDetails: JSON.parse(master),
    });
  };

  const closeOrderDetailsModal = () => {
    updateState({ selectedOrder: {}, selectedMasterForOrderDetails: {} });
  };

  // ----------------------------------------------------------------
  // Helper functions
  // ----------------------------------------------------------------
  const findAvailableRow = (rowEndIndexs, startIndex) => {
    for (let i = 0; i < rowEndIndexs.length; i++) {
      if (startIndex > rowEndIndexs[i]) {
        return i;
      }
    }
    return rowEndIndexs.length;
  };

  const groupAndAggregateNormalData = (data) => {
    const grouped = _.groupBy(
      data,
      (item) => `${item.masterId}-${item.suitesize}`
    );

    const groupedData = Object.values(grouped).map((group) => {
      const firstItem = group[0];
      const orderIds = new Set();

      let orders = group.reduce((acc, item) => {
        item.orders.forEach((order) => {
          if (!orderIds.has(order.orderId)) {
            orderIds.add(order.orderId);
            order.schedules = [];
            //----------------------------------------------------------------
            item.schedules.forEach((schedule) => {
              if (
                schedule.OrderUId == order.orderUId &&
                schedule.DateRequested &&
                moment(schedule.DateRequested).isSameOrAfter(
                  suiteCareState.defaultdaterange[0]
                ) &&
                moment(schedule.DateRequested).isSameOrBefore(
                  suiteCareState.defaultdaterange[
                  suiteCareState.defaultdaterange.length - 1
                  ])
                // &&
                // !(
                //   moment(schedule.DateRequested).isSameOrAfter(
                //     moment(order.moveindate)
                //   ) &&
                //   moment(schedule.DateRequested).isSameOrBefore(
                //     moment(order.moveoutdate)
                //   )
                // )
              ) {
                order.schedules.push(schedule);
              }
            });
            console.log(order.schedules, "order.schedules")
            // ----------------------------------------------------------------
            acc.push(order);
          }
        });
        return acc;
      }, []);

      orders = orders.filter((order) => {
        if (
          (moment(order.moveindate).isBefore(
            suiteCareState.defaultdaterange[0]
          ) &&
            moment(order.moveoutdate).isBefore(
              suiteCareState.defaultdaterange[0]
            )) ||
          (moment(order.moveindate).isAfter(
            suiteCareState.defaultdaterange[
            suiteCareState.defaultdaterange.length - 1
            ]
          ) &&
            moment(order.moveoutdate).isAfter(
              suiteCareState.defaultdaterange[
              suiteCareState.defaultdaterange.length - 1
              ]
            ))
        ) {
          return false;
        } else {
          return true;
        }
      });

      let ordersAndSchedulesForMaster = [];

      // making orders and schedules to a common format
      for (let i = 0; i < orders.length; i++) {
        const { schedules, ...rest } = orders[i];
        const { startIndex, span } = getBookingSpan(
          orders[i].moveindate,
          orders[i].moveoutdate
        );

        ordersAndSchedulesForMaster.push({
          ...rest,
          dataType: "order",
          startIndex,
          span,
          color: getColor(orders[i]),
          guestName: getGuestName(orders[i]),
        });

        ordersAndSchedulesForMaster.push(
          ...schedules.map((schedule) => {
            const { startIndex, span } = getBookingSpan(
              schedule.DateRequested,
              schedule.DateRequested
            );
            return {
              ...schedule,
              dataType: "schedule",
              startIndex,
              span,
              color: getScheduleColor(schedule.IsVendorConfirmed),
              guestName: null,
            };
          })
        );
      }
      // sort ordersAndSchedulesForMaster by start index
      ordersAndSchedulesForMaster.sort((a, b) => a.startIndex - b.startIndex);

      // Track the end date of the last drawn item in each row
      const rowEndIndexs = [];
      const rows = [];

      ordersAndSchedulesForMaster.forEach((O_S) => {
        const rowIndex = findAvailableRow(rowEndIndexs, O_S.startIndex);
        rowEndIndexs[rowIndex] = O_S.startIndex + O_S.span - 1;

        if (!rows[rowIndex]) rows[rowIndex] = [];
        rows[rowIndex].push(O_S);
      });

      return {
        masterId: firstItem.masterId,
        masterUId: firstItem.masterUId,
        communityname: firstItem.communityname,
        suiteno: firstItem.suiteno,
        suitesize: firstItem.suitesize,
        city: firstItem.city,
        BR: firstItem.BR,
        orders: orders,
        rows: rows,
      };
    });

    return groupedData;
  };

  const groupAndAggregateBillableData = (data) => {
    // Group data by masterId
    const groupedByMasterId = _.groupBy(data, "masterId");

    // Get keys and sort them in descending order (as numbers)
    const sortedMasterIds = Object.keys(groupedByMasterId).sort(
      (a, b) => b - a
    );

    // Process each masterId group
    const groupedData = sortedMasterIds
      .map((masterId) => {
        // Get the data for the current masterId
        const group = groupedByMasterId[masterId];
        // Sort the group by BR in ascending order
        const sortedGroup = group.sort((a, b) => {
          if (a.BR === undefined) return 1;
          if (b.BR === undefined) return -1;
          return a.BR - b.BR;
        });
        // Process each item in the sorted group
        const processedGroup = sortedGroup.map((item) => {
          const orderIds = new Set();
          let orders = item.orders.reduce((acc, order) => {
            if (!orderIds.has(order.orderId)) {
              orderIds.add(order.orderId);
              order.schedules = [];
              //----------------------------------------------------------------
              item.schedules.forEach((schedule) => {
                if (
                  schedule.OrderUId == order.orderUId &&
                  schedule.DateRequested &&
                  moment(schedule.DateRequested).isSameOrAfter(
                    suiteCareState.defaultdaterange[0]
                  ) &&
                  moment(schedule.DateRequested).isSameOrBefore(
                    suiteCareState.defaultdaterange[
                    suiteCareState.defaultdaterange.length - 1
                    ]
                  )
                  // &&
                  // !(
                  //   moment(schedule.DateRequested).isSameOrAfter(
                  //     moment(order.moveindate)
                  //   ) &&
                  //   moment(schedule.DateRequested).isSameOrBefore(
                  //     moment(order.moveoutdate)
                  //   )
                  // )
                ) {
                  order.schedules.push(schedule);
                }
              });
              // ----------------------------------------------------------------
              acc.push(order);
            }
            return acc;
          }, []);

          orders = orders.filter((order) => {
            if (
              (moment(order.moveindate).isBefore(
                suiteCareState.defaultdaterange[0]
              ) &&
                moment(order.moveoutdate).isBefore(
                  suiteCareState.defaultdaterange[0]
                )) ||
              (moment(order.moveindate).isAfter(
                suiteCareState.defaultdaterange[
                suiteCareState.defaultdaterange.length - 1
                ]
              ) &&
                moment(order.moveoutdate).isAfter(
                  suiteCareState.defaultdaterange[
                  suiteCareState.defaultdaterange.length - 1
                  ]
                ))
            ) {
              return false;
            } else {
              return true;
            }
          });

          let ordersAndSchedulesForMaster = [];

          // making orders and schedules to a common format
          for (let i = 0; i < orders.length; i++) {
            const { schedules, ...rest } = orders[i];
            const { startIndex, span } = getBookingSpan(
              orders[i].moveindate,
              orders[i].moveoutdate
            );

            ordersAndSchedulesForMaster.push({
              ...rest,
              dataType: "order",
              startIndex,
              span,
              color: getColor(orders[i]),
              guestName: getGuestName(orders[i]),
            });

            ordersAndSchedulesForMaster.push(
              ...schedules.map((schedule) => {
                const { startIndex, span } = getBookingSpan(
                  schedule.DateRequested,
                  schedule.DateRequested
                );
                return {
                  ...schedule,
                  dataType: "schedule",
                  startIndex,
                  span,
                  color: getScheduleColor(schedule.IsVendorConfirmed),
                  guestName: null,
                };
              })
            );
          }

          // sort ordersAndSchedulesForMaster by start index
          ordersAndSchedulesForMaster.sort(
            (a, b) => a.startIndex - b.startIndex
          );

          // Track the end date of the last drawn item in each row
          const rowEndIndexs = [];
          const rows = [];

          ordersAndSchedulesForMaster.forEach((O_S) => {
            const rowIndex = findAvailableRow(rowEndIndexs, O_S.startIndex);
            rowEndIndexs[rowIndex] = O_S.startIndex + O_S.span - 1;

            if (!rows[rowIndex]) rows[rowIndex] = [];
            rows[rowIndex].push(O_S);
          });

          return {
            masterId: item.masterId,
            masterUId: item.masterUId,
            communityname: item.communityname,
            suiteno: item.suiteno,
            suitesize: item.suitesize,
            BR: item.BR,
            city: item.city,
            orders: orders,
            rows: rows,
          };
        });

        return processedGroup;
      })
      .flat();

    return groupedData;
  };

  const uniqueCities = (aggregatedData) => {
    return [...new Set(aggregatedData.map((item) => item.city))];
  };

  const filterAggregatedData = () => {
    const { aggregatedMastersData, selectedCity, searchedText } =
      suiteCareState;
    let filteredData;

    // filter for city selected
    if (selectedCity !== "All") {
      filteredData = aggregatedMastersData.filter(
        (item) => item.city === selectedCity
      );
    } else {
      filteredData = aggregatedMastersData;
    }

    // filter for searched text
    if (searchedText) {
      filteredData = filteredData.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(searchedText)
        )
      );
    }
    updateState({ aggregatedMastersDataFiltered: filteredData });
  };

  // Function to calculate the span for each booking
  const getBookingSpan = (moveindate, moveoutdate) => {
    const dateRange = suiteCareState.defaultdaterange;
    const start = moment(moveindate);
    const end = moment(moveoutdate);
    let startIndex = dateRange.findIndex((date) => date.isSame(start, "day"));
    const endIndex = dateRange.findIndex((date) => date.isSame(end, "day"));
    let span;
    // ----------------------------------------------------------------
    // If both start and end indexes are -1 , it indicates that order/schedule
    // starts before displayed date range and end after the the displayed
    // date range
    // ----------------------------------------------------------------
    if (startIndex == -1 && endIndex == -1) {
      startIndex = 0;
      span = dateRange.length;
    }
    // ----------------------------------------------------------------
    // If both start index is -1 and endIndex is not -1, it indicates that order/schedule
    // starts before displayed date range and ends somewhere the the displayed
    // date range
    // ----------------------------------------------------------------
    else if (startIndex == -1 && endIndex != -1) {
      startIndex = 0;
      span = endIndex - startIndex + 1;
    }
    // ----------------------------------------------------------------
    // If both start index is not -1 and endIndex is not -1, it indicates
    // that order/schedule starts and ends in the the displayed date range
    // ----------------------------------------------------------------
    else if (startIndex != -1 && endIndex != -1) {
      span = endIndex - startIndex + 1;
    }
    // ----------------------------------------------------------------
    // If both start index is not -1 and endIndex is -1, it indicates
    // that order starts the the displayed date range , but ends outside
    // ----------------------------------------------------------------
    else if (startIndex != -1 && endIndex == -1) {
      span = dateRange.length - startIndex;
    }

    return { startIndex, span };
  };

  const getColor = (order) => {
    let colorObject = {
      PENDING: "#FF6961",
      ACTIVE: "#AEC6CF",
      TERMED: "#D3D3D3",
    };
    const color = colorObject[order.orderstatus]
      ? colorObject[order.orderstatus]
      : getRandomColor(order.orderId); // Assign a random color

    return color;
  };

  const getScheduleColor = (IsVendorConfirmed) => {
    if (IsVendorConfirmed) {
      return "#B0DAB6";
    } else {
      return "#F9B666";
    }
  };

  const getGuestName = (order) => {
    const guestName = `#${order.orderId} - ${order.guestfirstname || order.guestlastname
        ? `${order.guestfirstname} ${order.guestlastname}`
        : ""
      }`;
    return guestName;
  };

  return (
    <SuiteCareContext.Provider
      value={{
        suiteCareState,
        refreshPage,
        changeActiveTab,
        checkAndStoreSessionDetails,
        getClientData,
        changePageLoadingStatus,

        selectCity,
        filterAggregatedData,
        changeSearchtext,

        openOrderDetailsModal,
        closeOrderDetailsModal,

        openScheduleModal,

        openNewReservationModal,
      }}
    >
      {children}
    </SuiteCareContext.Provider>
  );
};

export { SuiteCareContext, SuiteCareProvider };
