import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

const SideBar = () => {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(history.location.pathname);

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      setActiveTab(location.pathname);
    });
    return () => {
      unlisten();
    };
  }, [history]);
  function closeNav() {
    document.getElementById("wrapper").classList.remove("enlarged");
  }

  function openNav() {
    $("#wrapper").toggleClass("sidebarwidth");
  }
  return (
    <div className="left side-menu">
      <button
        type="button"
        className="button-menu-mobile button-menu-mobile-topbar open-left waves-effect"
        onClick={closeNav}
      >
        <i className="ion-close"></i>
      </button>

      <div className="topbar-left">
        <div className="text-center">
          <a href="/" className="logo">
            <img src="/images/bookit/CAR.svg" height="30" alt="logo" />
          </a>
        </div>
      </div>

      <div className="clearfix"></div>
      <div id="main">
        <button class="openbtn" onClick={() => openNav()}>
          <i class="fa fa-angle-double-right"></i>
        </button>
      </div>
      <div id="mySidebar" className="sidebar">
        <ul>
          <li id="dashboard">
            <Link
              to="/dashboard"
              className={`waves-effect ${
                activeTab == "/dashboard" ? "active" : ""
              }  `}
              aria-label="dashboard"
            >
              <i className="mdi mdi-airplay"></i>
              <span> Dashboard </span>
            </Link>
          </li>
          <li id="yourTeam">
            <Link
              to="/your-team"
              className={`waves-effect ${
                activeTab == "/your-team" ? "active" : ""
              }  `}
              aria-label="yourTeam"
            >
              <i className="mdi mdi-account-multiple-outline"></i>
              <span> Your Team </span>
            </Link>
          </li>
          <li id="inventory">
            <Link
              to="/inventory"
              className={`waves-effect ${
                activeTab == "/inventory" ? "active" : ""
              }  `}
              aria-label="inventory"
            >
              <i className="mdi mdi-calendar"></i>
              <span>Avail Calender</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
